import { City, Country, State } from "react-country-state-city";
import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { getDocumentData } from "../../Firebase/cloudFirestore/getData";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// import MultiSelectDropdown from "../../../Compunents/MultiSelectdropDown/MultiSelectDropdown";
import { updateDocument } from "../../Firebase/cloudFirestore/updateData";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { deleteDocument } from "../../Firebase";
import {
  getAllPlans,
  getSubscriberByNumber,
  updatePackageinSubcriber,
} from "../service/admin";
// import Snakbar from "../../../Compunents/Snackbar/Snakbar";
import { loader, snackbar } from "../../utils";
import moment from "moment";
export default function ProfileEdit({ setLoader, getUserData, activeTab }) {
  let { id } = useParams();
  const { handleSubmit } = useForm();
  const countries = Country?.getAllCountries();
  const [isEdit, setIsEdit] = useState(true);
  const [formData, setFormData] = useState({});
  const [formData2, setFormData2] = useState({});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [allPlan, setAllPlan] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [userInfo, setUserInfo] = useState();

  async function getAllPackages() {
    try {
      let res = await getAllPlans();
      setAllPlan(res?.data?.data || []);
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  // async function getAllPackages() {
  //   try {
  //     let userdetails = await getSubscriberByNumber({ mobile_number: id }).then(
  //       (res) => {
  //         return res.data.data;
  //       }
  //     );
  //     let res = await getAllPlans();
  //     setAllPlan(res?.data?.data || []);
  //     setUserInfo(userdetails);
  //     if (
  //       userdetails?.package_details &&
  //       userdetails?.package_details?.package_id
  //     ) {
  //       setSelectedPackage(userdetails?.package_details?.package_id);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //   }
  // }
  async function assignPackage() {
    try {
      if (selectedPackage && selectedPackage.trim().length !== 0) {
        let payload = {
          package_details: {
            package_id: selectedPackage,
            // "select_package": "string",
            // "package_validity": "2024-11-06"
          },
        };
        console.log(userInfo);
        let res = await updatePackageinSubcriber(userInfo._id, payload);
        snackbar.success("Assign Package is done");
      } else {
        snackbar.error("Please select Package");
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }
  const [industry, setIndustry] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [snackbar1, showSnackbar1] = useState({
    show: false,
    msg: "data added",
    type: "error",
  });

  const industries = [
    "Information Technology",
    "Graphics Design",
    "Healthcare",
    "Finance",
    "Education",
    "Manufacturing",
    "Retail",
    "Real Estate",
    "Transportation",
    "Construction",
    "Energy",
    "Telecommunications",
    "Hospitality",
    "Media",
    "Entertainment",
    "Consulting",
    "Automotive",
    "Agriculture",
    "Pharmaceutical",
    "Aerospace",
  ];

  const [data, setData] = useState({});

  async function getUserDataInner() {
    setLoader(true);
    try {
      let userdetails = await getUserData()
      console.log(userdetails, "userdetails");
      setFormData(userdetails);
      //   setFormData2(res);
      //   setIndustry(res?.industry);
      //   let country = countries?.find((item) => item.name === res?.country);
      //   setCountry(JSON.stringify(country));
      //   getAllStates(JSON.stringify(country));
      // setCity(res?.city)
      setData(userdetails);
      let res = await getAllPlans();
      setAllPlan(res?.data?.data || []);
      setUserInfo(userdetails);
      if (
        userdetails?.package_details &&
        userdetails?.package_details?.package_id
      ) {
        setSelectedPackage(userdetails?.package_details?.package_id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  }
  useEffect(() => {}, []);
  useEffect(() => {
    if (activeTab == 0) {
      getAllPackages();
      getUserDataInner();
    }
  }, [activeTab]);

  //   useEffect(() => {
  //     if (states.length !== 0) {
  //       let state = states?.find((res) => res?.name === formData?.state);
  //       if (state !== undefined) {
  //         setState(JSON.stringify(state));
  //         getAllCities(JSON.stringify(state));
  //       }
  //     }
  //   }, [states]);

  //   function getAllStates(res) {
  //     setCountry(res);
  //     let obj = JSON.parse(res);
  //     let states = State.getStatesOfCountry(obj?.isoCode);
  //     setStates(states);
  //   }

  //   function getAllCities(res) {
  //     setState(res);
  //     let obj = JSON.parse(res);
  //     let cities = City.getCitiesOfState(obj?.countryCode, obj?.isoCode);
  //     setCities(cities);
  //   }

  //   async function submitData(data) {
  //     let cc = JSON.parse(country);
  //     let ss = JSON.parse(state);
  //     let payload = {
  //       ...formData,
  //       country: cc?.name,
  //       state: ss?.name,
  //       industry: industry,
  //     };
  //     setLoader(true);
  //     try {
  //       await updateDocument("users", id, payload);
  //       showSnackbar({
  //         show: true,
  //         msg: "User data updated successfully",
  //         type: "success",
  //       });
  //     } catch (err) {
  //       console.log(err);
  //       showSnackbar({
  //         show: true,
  //         msg: "There are some problem please try again",
  //         type: "error",
  //       });
  //       return;
  //     } finally {
  //       await getUserData();
  //       setLoader(false);
  //       setIsEdit(true);
  //     }
  //   }

  function handleInput(e) {
    let { value, name } = e.target;
    if (value === " ") {
      e.target.value = "";
    } else {
      setFormData((pre) => {
        return {
          ...pre,
          [name]: value,
        };
      });
    }
  }

  const DeleteUser = async (e) => {
    await deleteDocument("Users", e);
  };

  return (
    <>
      {/* <Snakbar data={snackbar} setData={showSnackbar} /> */}
      <div className="">
        <div className="d-flex justify-content-between align-items-center">
          <div className="fs-5 fw-600 text-black">Profile Details</div>{" "}
          <span>
            {" "}
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => assignPackage()}
            >
              Assign Package
            </button>
          </span>
          {/* <button
            className="btn btn-primary d-flex align-items-center"
            onClick={() => {
              setIsEdit(!isEdit);
              setFormData(formData2);
            }}
          >
            {isEdit ? (
              <>
                <FaRegEdit className="me-1" /> Edit
              </>
            ) : (
              "cancel"
            )}
          </button> */}
        </div>
        <form
          className="w-100"
          // onSubmit={handleSubmit(submitData)}
        >
          <div className="row mt-4">
            <div className="col-md-6 col-sm-6 col-12 mt-3">
              <label className="form-label mb-1">Name</label>
              <input
                className="form-control"
                placeholder="Enter business owner name"
                required
                disabled={isEdit}
                name="owner_name"
                value={formData?.name}
                onChange={handleInput}
              />
            </div>

            <div className="col-md-6 col-sm-6 col-12 mt-3">
              <label className="form-label mb-1">Phone Number</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter phone number"
                required
                disabled={isEdit}
                name="phoneNumber"
                value={formData?.mobile_number}
                onChange={handleInput}
                onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-12 mt-3">
              <label className="form-label mb-1">Email Address</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email address"
                required
                disabled={isEdit}
                name="email"
                value={formData?.email}
                onChange={handleInput}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-12 mt-3">
              <label className="form-label mb-1">Created At</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email address"
                required
                disabled={isEdit}
                name="email"
                value={moment(formData?.createdAt).format('DD-MM-YYYY hh:mm A')}
                onChange={handleInput}
              />
            </div>

            <div className="col-md-12 mt-3">
              <label className="form-label mb-1">Address</label>
              <textarea
                rows="4"
                className="form-control"
                placeholder="Enter full address"
                required
                disabled={isEdit}
                name="address"
                value={formData?.Address ? formData?.Address : "N/A"}
                onChange={handleInput}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="form-label mb-1">Assign Package</label>
              <select
                className="form-control"
                // disabled={isEdit}
                value={selectedPackage}
                onChange={(e) => setSelectedPackage(e.target.value)}
              >
                <option value="">Select Package</option>
                {allPlan.map((pkg, index) => (
                  <option key={index} value={pkg._id}>
                    {pkg.plackage_name}
                  </option>
                ))}
              </select>
            </div>
            <ConfirmModal data={id} onsubmit={DeleteUser} display={"user"}>
              <div className="mt-4">
                <button type="button" className="btn btn-primary">
                  Delete User
                </button>
              </div>
            </ConfirmModal>

            {isEdit ? (
              ""
            ) : (
              <div className="d-flex justify-content-end gap-3 mt-4">
                <button className="btn btn-primary">Submit</button>
              </div>
            )}
          </div>
        </form>

        <div className="w-100">
          <div className="d-flex flex-column"></div>
        </div>
      </div>
    </>
  );
}
