import React from "react";
import { Routes, Route, Navigate } from "react-router";
import { WrongPath } from "./WrongPath";
import { LoginPage } from "./Components/Login/LoginPage";
import { CheckAuth } from "./auth/CheckAuth";
import { LoginKeeper } from "./auth/LoginKeeper";
import UsersListing from "./Components/Users/UsersListing";
import { Backdrop } from "@mui/material";
import { Triangle } from "react-loader-spinner";
import { useSelector } from "react-redux";
import AddTheme from "./Components/AddTheme/AddTheme";
import Eventmanagement from "./Components/EventManagement/Eventmanagement";
import DropdownWithEditDelete from "./Components/testing";
import HomeAutomation from "./Components/Users/HomeAutomation";
import Subscribers from "./Components/Users/Subscribers";
import ViewUsers from "./Components/View/ViewUsers";
import ChannelManagement from "./Components/ChannelManagement/ChannelManagement";
import ChannelView from "./Components/View/ChannelView";
import Banners from "./Pages/Banners/Banners";
// import Banners from "./Pages/AdvatismentBanner/Banners";
import PackageListing from "./Pages/Packages/PackageListing";
import { GenreListing } from "./Pages/Genre/GenreListing";
import Languages from "./Pages/Languages/Languages";
import Broadcaster from "./Pages/Broadcaster/Broadcaster";
import Categories from "./Pages/Categories/Categories";
import TVBanner from "./Pages/Banners/TVBanner";
import PrivacyPolicy from "./Pages/teams_Policy/PrivacyPolicy";
import TermsConditions from "./Pages/teams_Policy/TermsConditions";
import AdBanners from "./Pages/AdvatismentBanner/AdBanners";
import AdTVBanner from "./Pages/AdvatismentBanner/AdTVBanner";

export const Router = () => {
  let loader = useSelector((e) => e.loader);
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 99999999999 }} open={loader}>
        <Triangle
          height="80"
          width="80"
          color="black"
          ariaLabel="triangle-loading"
          visible={loader}
        />
      </Backdrop>

      <Routes>
        <Route element={<LoginKeeper />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>

        <Route element={<CheckAuth />}>
          {/* <Route exact path="/qrcode" element={<Qrcode />} /> */}
          <Route exact path="/subscribers" element={<Subscribers />} />
          <Route path="/" element={<Navigate to="/userlisting" />} />
          <Route exact path="/userlisting" element={<UsersListing />} />
          <Route exact path="/channel" element={<ChannelManagement />} />
          <Route exact path="/addTheme" element={<AddTheme />} />
          <Route exact path="/eventmanagement" element={<Eventmanagement />} />
          <Route exact path="/testing" element={<DropdownWithEditDelete />} />
          <Route exact path="/homeautomation" element={<HomeAutomation />} />
          <Route path="/userlisting/view/:id" element={<ViewUsers />} />
          <Route path="/channel/view/:id" element={<ChannelView />} />

          <Route path="/banners/TVBanners" element={<TVBanner />} />
          <Route path="/banners/AppBanners" element={<Banners />} />

          <Route path="/Advertisement/TVAdBanners" element={<AdTVBanner />} />
          <Route path="/Advertisement/AppAdBanners" element={<AdBanners />} />

          <Route path="/packageListing" element={<PackageListing />} />
          <Route path="/media/genreListing" element={<GenreListing />} />
          <Route path="/media/languages" element={<Languages />} />
          <Route path="/media/broadcaster" element={<Broadcaster />} />
          <Route path="/media/categories" element={<Categories />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsConditions" element={<TermsConditions />} />

          {/* <Route exact path="/testing" element={<div>Test Route</div>} /> */}
        </Route>
        <Route path="*" element={<WrongPath />} />
      </Routes>
    </>
  );
};
