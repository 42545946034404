import axios from "axios";

import { API, baseUrl } from "./Api";

let token = "YWRtaW46UGl5dXNoQDEyMw==";

export const addPlan = async (payload) => {
  return await axios.post(`${baseUrl}${API.plan.addPlan}`, payload);
};

export const getPlanByCreator = async (id) => {
  return await axios.get(`${baseUrl}${API.plan.getPlanByCreator}${id}`);
};

export const getAllPlans = async () => {
  return await axios.get(`${baseUrl}${API.plan.getAllPlans}`);
};

export const getallChannel = async () => {
  return await axios.get(`${baseUrl}${API.plan.getAllChannel}`);
};

export const updateChannelById = async (id, payload) => {
  return await axios.put(
    `${baseUrl}${API.plan.updateChannelById}${id}`,
    payload
  );
};

export const channelCategories = async () => {
  return await axios.get(`${baseUrl}${API.plan.getchannelCategory}`);
};
export const deleteCategory = async (id) => {
  return await axios.delete(`${baseUrl}${API.plan.deleteCategory}${id}`);
};
export const updateCategory = async (id, body) => {
  return await axios.put(`${baseUrl}${API.plan.updateCategory}${id}`, body);
};

export const addCategory = async (payload) => {
  return await axios.post(`${baseUrl}${API.plan.addCategory}`, payload);
};

export const addChannel = async (payload) => {
  return await axios.post(`${baseUrl}${API.plan.addChannel}`, payload);
};

export const channelByFilter = async (payload) => {
  return await axios.post(`${baseUrl}${API.plan.channelByFilter}`, payload);
};

export const deletePlan = async (id) => {
  return await axios.post(`${baseUrl}${API.plan.deletePlan}${id}`);
};
export const getPlanById = async (id) => {
  return await axios.get(`${baseUrl}${API.plan.getPlanById}${id}`);
};

export const bulkInsertPlan = async (payload) => {
  return await axios.post(`${baseUrl}${API.plan.bulkInsertPlan}`, payload);
};

export const getChannelById = async (id) => {
  return await axios.get(`${baseUrl}${API.plan.getChannelById}${id}`);
};

export const fatchallChannel = async () => {
  return await axios.get(`${baseUrl}${API.plan.getAllChannelByCategory}`);
};

export const updatePackage = async (id, payload) => {
  return await axios.put(`${baseUrl}${API.plan.updatePlan}/${id}`, payload);
};

//           User           //

export const addUser = async (payload) => {
  return await axios.post(`${baseUrl}${API.user.addUser}`, payload);
};

export const getAlluser = async (payload) => {
  return await axios.post(`${baseUrl}${API.user.getAllUser}`, payload);
};

export const deleteUser = async (id) => {
  return await axios.delete(`${baseUrl}${API.user.deleteUser}${id}`);
};

export const getUserById = async (id) => {
  return await axios.get(`${baseUrl}${API.user.getUserById}${id}`);
};

export const updateUser = async (id, payload) => {
  return await axios.put(`${baseUrl}${API.user.updateUser}${id}`, payload);
};

export const updateMemberStatus = async (id) => {
  return await axios.put(`${baseUrl}${API.user.updateMemberStatus}${id}`);
};

export const planStatusChanges = async (id) => {
  return await axios.put(`${baseUrl}${API.plan.statusChanges}${id}`);
};

export const uploadFile = async (payload) => {
  return await axios.post(`${baseUrl}${API.user.uploadFile}`, payload);
};

// export const getFilterUser = async (payload) => {
//   return await axios.post(`${baseUrl}${API.user.getFilterUser}`,payload);
// };

// subscriber management

export const uploadDocs = async (payload) => {
  return await axios.post(`${baseUrl}${API.document.upload}`, payload);
};

export const deleteDocs = async (payload) => {
  return await axios.post(`${baseUrl}${API.document.deleteDocs}`, payload);
};

export const addSubscriber = async (payload) => {
  return await axios.post(
    `${baseUrl}${API.subscriberManagement.addSubscriber}`,
    payload
  );
};

export const getAllSubscriber = async () => {
  return await axios.get(
    `${baseUrl}${API.subscriberManagement.getAllSubscriber}`
  );
};

export const getSubscriberById = async (id) => {
  return await axios.get(
    `${baseUrl}${API.subscriberManagement.getSubscriberById}${id}`
  );
};

export const deleteSubscriber = async (id, payload) => {
  return await axios.post(
    `${baseUrl}${API.subscriberManagement.deleteSubscriber}${id}`,
    payload
  );
};

export const deleteChannel = async (id, payload) => {
  return await axios.post(`${baseUrl}${API.plan.deleteChannel}${id}`);
};

export const updateSubscriber = async (id, payload) => {
  return await axios.put(
    `${baseUrl}${API.subscriberManagement.updateSubscriber}${id}`,
    payload
  );
};

export const updateSubscriberStatus = async (id) => {
  return await axios.get(
    `${baseUrl}${API.subscriberManagement.updateSubscriberStatus}${id}`
  );
};

export const addInvoiceData = async (payload) => {
  return await axios.post(`${baseUrl}${API.invoice.addInvoices}`, payload);
};

export const getInvoiceDataById = async (id) => {
  return await axios.get(`${baseUrl}${API.invoice.getInvoiceById}${id}`);
};

export const addRole = async (payload) => {
  return await axios.post(`${baseUrl}${API.role.addRole}`, payload);
};

export const getAllRole = async () => {
  return await axios.post(`${baseUrl}${API.role.getAllRole}`);
};

export const deleteRoleById = async (id) => {
  return await axios.delete(`${baseUrl}${API.role.deleteRole}${id}`);
};

export const updatePermission = async (payload, id) => {
  return await axios.put(
    `${baseUrl}${API.role.updatePermission}${id}`,
    payload
  );
};
export const getEventLogsAllData = async (page, limit, payload) => {
  return await axios.post(
    `${baseUrl}${API.logs.getEventLogsAll}?page=${page}&limit=${limit}`,
    payload
  );
};

export const getRoleDatabyId = async (id) => {
  return await axios.get(`${baseUrl}${API.role.getRoleDatabyId}${id}`);
};

export const getRolePermissions = async (role) => {
  return await axios.post(`${baseUrl}${API.role.getPermissionsByRole}`, role);
};

export const getPdfInvoiceId = async (id) => {
  return await axios.get(`${baseUrl}${API.invoice.getInvoicePdfById}${id}`);
};

export const loginAuth = async (payload) => {
  return await axios.post(`${baseUrl}${API.auth.login}`, payload);
};
export const forgetPasswordAuth = async (payload) => {
  return await axios.post(`${baseUrl}${API.auth.forgetPassword}`, payload);
};
export const changePasswordAuth = async (payload) => {
  return await axios.post(`${baseUrl}${API.auth.changePassword}`, payload);
};
export const OtpSend = async (payload) => {
  return await axios.post(`${baseUrl}${API.auth.SendOtp}`, payload);
};

export const verifyOTP = async (payload) => {
  return await axios.post(`${baseUrl}${API.auth.VerifyOtp}`, payload);
};

export const checkUserTokenIsValidAuth = async (payload) => {
  return await axios.post(
    `${baseUrl}${API.auth.checkUserTokenIsValid}`,
    payload
  );
};

export const addLanguage = async (payload) => {
  return await axios.post(`${baseUrl}${API.language.addLanguage}`, payload);
};
export const allLanguage = async (payload) => {
  return await axios.get(`${baseUrl}${API.language.allLanguage}`, payload);
};
export const updateLanguage = async (id, payload) => {
  return await axios.put(
    `${baseUrl}${API.language.updateLanguage}${id}`,
    payload
  );
};
export const deleteLanguage = async (id) => {
  return await axios.delete(`${baseUrl}${API.language.deleteLanguage}${id}`);
};

export const addGenre = async (payload) => {
  return await axios.post(`${baseUrl}${API.genre.addGenre}`, payload);
};
export const allGenre = async (payload) => {
  return await axios.get(`${baseUrl}${API.genre.allGenre}`, payload);
};
export const updateGenre = async (id, payload) => {
  return await axios.put(`${baseUrl}${API.genre.updateGenre}${id}`, payload);
};
export const deleteGenre = async (id) => {
  return await axios.delete(`${baseUrl}${API.genre.deleteGenre}${id}`);
};

export const addBroadcaster = async (payload) => {
  return await axios.post(
    `${baseUrl}${API.broadcaster.addBroadcaster}`,
    payload
  );
};

export const getBroadcasters = async (payload) => {
  return await axios.get(`${baseUrl}${API.broadcaster.getAllBroadcaster}`);
};

export const updateBroadcaster = async (id, payload) => {
  console.log(payload);
  return await axios.put(
    `${baseUrl}${API.broadcaster.updateBroadcaster}${id}`,
    payload
  );
};

export const deleteBroadcaster = async (id) => {
  return await axios.delete(
    `${baseUrl}${API.broadcaster.deletBroadcaster}${id}`
  );
};
// packages apis
export const createPackage = async (body) => {
  return await axios.post(`${baseUrl}${API.package.add}`, body);
};

// headend api routes

export const getAllChannelHeadends = async (parms) => {
  return await axios.get(
    `http://103.212.89.157:8085/streamer/api/v3/streams?limit=1000${parms}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const disableEnableChannel = async (name, payload) => {
  return await axios.put(
    `http://103.212.89.157:8085/streamer/api/v3/streams/${name}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const addBanner = async (payload) => {
  return await axios.post(`${baseUrl}${API.banner.addBanner}`, payload);
};
export const getBannerData = async () => {
  return await axios.get(`${baseUrl}${API.banner.getBanners}`);
};
export const deleteBannerImg = async (id) => {
  return await axios.delete(`${baseUrl}${API.banner.deleteBanner}${id}`);
};

export const addAppBanner = async (payload) => {
  return await axios.post(`${baseUrl}${API.banner.addAppBanner}`, payload);
};
export const getAppBanners = async () => {
  return await axios.get(`${baseUrl}${API.banner.getAppBanners}`);
};
export const deleteAppBanner = async (id) => {
  return await axios.delete(`${baseUrl}${API.banner.deleteAppBanner}${id}`);
};

export const updatePackageinSubcriber = async (id, payload) => {
  return await axios.post(
    `${baseUrl}${API.subscriberManagement.updatePackage}${id}`,
    payload
  );
};

export const getSubscriberByNumber = async (payload) => {
  return await axios.post(
    `${baseUrl}${API.subscriberManagement.getSubscriberByNumber}`,
    payload
  );
};

export const addPolicy = async (payload) => {
  return await axios.post(
    `${baseUrl}${API.policy_condition.addPolicy}`,
    payload
  );
};
export const addTeamCondition = async (payload) => {
  return await axios.post(
    `${baseUrl}${API.policy_condition.addTeamCondition}`,
    payload
  );
};

export const getPolicy = async (payload) => {
  return await axios.get(
    `${baseUrl}${API.policy_condition.getPolicy}`,
    payload
  );
};

export const getTeamCondition = async (payload) => {
  return await axios.get(
    `${baseUrl}${API.policy_condition.getTeamCondition}`,
    payload
  );
};
export const setSubscriptionManagement = async (id, payload) => {
  return await axios.post(
    `${baseUrl}${API.subscriberManagement.setSubscriptionManagement}${id}`,
    payload
  );
};


export const getTVAdBanners = async () => {
  return await axios.get(`${baseUrl}${API.adBanners.getTVBanners}`);
};

export const getAppAdBanners = async () => {
  return await axios.get(`${baseUrl}${API.adBanners.getAppBanners}`);
};


export const updateCategorySequence = async (payload) => {
  return await axios.post(`${baseUrl}${API.plan.updateSeq}`,payload);
};
