import { City, Country, State } from "react-country-state-city";
import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";

import { useNavigate, useParams } from "react-router-dom";
import "./channel.css";
import { useForm } from "react-hook-form";
import {
  allGenre,
  allLanguage,
  channelCategories,
  deleteChannel,
  getBroadcasters,
  updateChannelById,
} from "../service/admin";
import { loader,snackbar } from "../../utils";
import { Switch } from "@mui/material";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
// import MultiSelectDropdown from "../../../Compunents/MultiSelectdropDown/MultiSelectDropdown";
// import Snakbar from "../../../Compunents/Snackbar/Snakbar";

export default function ChannelEdit({ imgUrl, getUserData, activeTab }) {
  let { id } = useParams();
  const { handleSubmit } = useForm();
  const countries = Country?.getAllCountries();
  const [isEdit, setIsEdit] = useState(true);
  const [formData, setFormData] = useState({});
  const [formData2, setFormData2] = useState({});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [snackbar2, showSnackbar] = useState({
    show: false,
    msg: "data added",
    type: "error",
  });

  const industries = [
    "Information Technology",
    "Graphics Design",
    "Healthcare",
    "Finance",
    "Education",
    "Manufacturing",
    "Retail",
    "Real Estate",
    "Transportation",
    "Construction",
    "Energy",
    "Telecommunications",
    "Hospitality",
    "Media",
    "Entertainment",
    "Consulting",
    "Automotive",
    "Agriculture",
    "Pharmaceutical",
    "Aerospace",
  ];

  async function getUserDataInner() {
    loader.start();
    try {
      let res = await getUserData();
      setFormData(res);
      setFormData2(res);
      setIndustry(res?.industry);
    } catch (err) {
      console.log(err);
    } finally {
      loader.stop();
    }
  }

  useEffect(() => {
    if (activeTab == 0) {
      getUserDataInner();
      fetchCategories();
      getBroadCast();
    }
  }, [activeTab,imgUrl]);

  async function submitData(data) {
    let payload = {
      ...formData,
      // user_id: "123",
      // user_name: "123",
      // user_role: "123",
    };
    delete payload._id;
    delete payload.channelId;
    delete payload.resource_id;
    delete payload.enc_token;
    delete payload.expires;
    delete payload.channalToken;
    delete payload.createdAt;
    delete payload.updatedAt;
    delete payload.__v;
    delete payload.languageData;
    delete payload.genreData;

    loader.start();
    try {
      await updateChannelById(id, payload);
      setSuccess('Channel data updated successfully')
    } catch (err) {
      console.log(err);
      setError('There are some problem please try again')
      return;
    } finally {
      await getUserData();
      await getUserDataInner();
      loader.stop();
      setIsEdit(true);
    }
  }

  function handleInput(e) {
    let { value, name } = e.target;
    if (value === " ") {
      e.target.value = "";
    } else {
      setFormData((pre) => {
        return {
          ...pre,
          [name]: value,
        };
      });
    }
  }

  const navigate = useNavigate();
  const DeleteChannel = async () => {
    let payload = {
      // user_id: "123",
      // user_name: "123",
      // user_role: "123",
    };
    try {
      loader.start();
      await deleteChannel(id, payload);
      navigate("/channel");
      loader.stop();
    } catch (err) {
      console.log("error");
    }
  };

  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      const response = await channelCategories();
      if (response.data.error) {
        console.error("Error fetching categories:", response.data.errormessage);
        return;
      }
      setCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const [broadCast, setBroadCast] = useState([]);
  const getBroadCast = async () => {
    try {
      const response = await getBroadcasters();
      setBroadCast(response?.data?.data);
    } catch (error) {
      console.log(broadCast);
    }
  };

  const [genre, setGenre] = useState([]);
  useEffect(() => {
    const getGenre = async () => {
      try {
        const response = await allGenre();
        setGenre(response?.data?.data);
        // console.log(response.data.data, "all genre-----");
      } catch (error) {
        console.log(error);
      }
    };

    getGenre();
  }, []);

  const [lang, setLang] = useState([]);

  useEffect(() => {
    const getLanguages = async () => {
      try {
        const response = await allLanguage();
        setLang(response?.data?.data);
        // console.log(response.data.data, "all languages-----");
      } catch (error) {
        console.log(error);
      }
    };

    getLanguages();
  }, []);

  function setError(message) {
    snackbar.error(message);
  }
  function setSuccess(message) {
    snackbar.success(message);
  }

  return (
    <>
      {/* <Snakbar data={snackbar} setData={showSnackbar} /> */}
      <div className="">
        <div className="d-flex justify-content-between align-items-center">
          <div className="fs-5 fw-600 text-black">Channel Details</div>
          <button
            className="btn btn-primary d-flex align-items-center"
            onClick={() => {
              setIsEdit(!isEdit);
              setFormData(formData2);
            }}
          >
            {isEdit ? (
              <>
                <FaRegEdit className="me-1" /> Edit
              </>
            ) : (
              "cancel"
            )}
          </button>
        </div>

        {/* {
            "_id": "66bb4d1cd9b966b0e9a4f16e",
            "channelId": "Epic_2ilzsdrms5",
            "name": "Epic",
            "description": "Epic",
            "category": "668b8ea36da0b023d7238b4a",
            "channel_no": "865",
            "fta": true,
            "definition": "High definition",
            "select_broadcaster": "66b08d2f3b44eebabbeaaec0",
            "logo": {
                "file_name": "SmsDocuments/1723551001616EPIC-Channel-Logo.png",
                "file_url": "https://ott-fast-data.s3.ap-southeast-1.amazonaws.com/SmsDocuments/1723551001616EPIC-Channel-Logo.png"
            },
            "age_restriction": false,
            "channel_url": "hls://192.168.140.141/hls/modn/list/8013/playlist.m3u8",
            "language": "66acc82b62bb1771cbd753e3",
            "genre": "66accc91f2b350c60498f7e9",
            "cost": 0,
            "resource_id": "Epic_2ilzsdrms5",
            "enc_token": "eyJhY2Nlc3Nfa2V5IjoiT1BKT1B3a25aanJUXC83N0duTnJCNGJuTzFocG5FNlFSV0hyYlhBdFowajZrQUw3b1EweEw4RXJNaFptR3Zwc0giLCJzaXRlX2lkIjoiVThXWCJ9",
            "expires": "60000",
            "channalToken": "eyJkcm1fdHlwZSI6IldpZGV2aW5lIiwic2l0ZV9pZCI6IlU4V1giLCJ1c2VyX2lkIjoiTElDRU5TRVRPS0VOIiwiY2lkIjoiRXBpY18yaWx6c2RybXM1IiwicG9saWN5IjoiWXFpRjhVeUE5TXdDclNZS0dRcnl1WmtnY25LQkR6T0F3dVJjRWRCOW9zUW95TDBEejlUNGQxUWFYREljZm5TQ2VuNjdGY29Dbkl0THowSVdpWXRhNWc9PSIsInRpbWVzdGFtcCI6IjIwMjQtMDgtMTNUMTI6MTA6MDRaIiwiaGFzaCI6ImxoaDZCRmV3ZWl1bmlUY3NKcXZtRHd4WExDS2VtU0J5dW5EMEgzaXM4dEE9IiwicmVzcG9uc2VfZm9ybWF0Ijoib3JpZ2luYWwiLCJrZXlfcm90YXRpb24iOnRydWV9",
            "createdAt": "2024-08-13T12:10:04.951Z",
            "updatedAt": "2024-08-13T12:10:04.951Z",
            "__v": 0,
            "languageData": [
                {
                    "_id": "66acc82b62bb1771cbd753e3",
                    "name": "Gujarati",
                    "createdAt": "2024-08-02T11:51:07.404Z",
                    "updatedAt": "2024-08-02T12:01:55.635Z",
                    "__v": 0
                }
            ],
            "genreData": [
                {
                    "_id": "66accc91f2b350c60498f7e9",
                    "name": "comedy",
                    "createdAt": "2024-08-02T12:09:53.213Z",
                    "updatedAt": "2024-08-02T12:10:36.052Z",
                    "__v": 0
                }
            ]
        } */}

        <form className="w-100" onSubmit={handleSubmit(submitData)}>
          <div className="row mt-4">
            <div className="col-md-12 mt-2">
              <label className="form-label mb-1" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter channel name"
                id="name"
                required
                disabled={isEdit}
                name="name"
                value={formData.name}
                onChange={handleInput}
              />
            </div>

            <div className="col-md-12 mt-2">
              <label className="form-label mb-1" htmlFor="description">
                Description
              </label>
              <textarea
                rows={5}
                type="text"
                className="form-control"
                id="description"
                required
                disabled={isEdit}
                name="description"
                placeholder="Enter description here"
                value={formData.description}
                onChange={handleInput}
              />
            </div>
          </div>

          <div className="form-row mb-3 mt-0 d-flex categoryrow">
            <div className="form-group col-md-12">
              <label className="form-label mb-1" htmlFor="category">
                Category
              </label>
              <div className="d-flex">
                <select
                  id="category"
                  className="form-control"
                  value={formData.category}
                  onChange={handleInput}
                  required
                  disabled={isEdit}
                  name="category"
                >
                  <option disabled value="">
                    Select Category
                  </option>
                  {categories.map((category) => (
                    <option
                      style={{
                        padding: "10px auto",
                        borderBottom: "0.2px solid black",
                        WebkitAppearance: "none",
                      }}
                      key={category._id}
                      value={category._id}
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
                {/* <FaPlus
                  style={{
                    color: "#202020",
                    transform: "scale(1.3)",
                    margin: "auto auto",
                    marginLeft: "10px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={toggleModal}
                /> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label className="form-label mb-1" htmlFor="ChannelGenre">
                Channel BroadCaster
              </label>
              <select
                required
                id="select_broadcaster"
                className="form-control"
                value={formData.select_broadcaster}
                onChange={handleInput}
                disabled={isEdit}
                name="select_broadcaster"
              >
                <option disabled value="">
                  Select BroadCaster
                </option>
                {broadCast?.map((item) => (
                  <option
                    style={{
                      padding: "10px auto",
                      borderBottom: "0.2px solid black",
                      WebkitAppearance: "none",
                    }}
                    key={item?._id}
                    value={item?._id}
                  >
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mt-2">
              <label className="form-label mb-1" htmlFor="ChannelGenre">
                Channel Genre
              </label>
              <select
                required
                id="genre"
                className="form-control"
                value={formData.genre}
                onChange={handleInput}
                disabled={isEdit}
                name="genre"
              >
                <option disabled value="">
                  Select Genre
                </option>
                {genre?.map((item) => (
                  <option
                    style={{
                      padding: "10px auto",
                      borderBottom: "0.2px solid black",
                      WebkitAppearance: "none",
                    }}
                    key={item?._id}
                    value={item?._id}
                  >
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-6 mt-2">
              <label className="form-label mb-1" htmlFor="ChannelLanguage">
                Channel Language
              </label>
              <select
                required
                id="language"
                className="form-control"
                name="language"
                value={formData.language}
                onChange={handleInput}
                disabled={isEdit}
              >
                <option disabled value="">
                  Select Language
                </option>
                {lang?.map((item1) => (
                  <option
                    style={{
                      padding: "10px auto",
                      borderBottom: "0.2px solid black",
                      WebkitAppearance: "none",
                    }}
                    key={item1?._id}
                    value={item1?._id}
                  >
                    {item1?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mt-2">
              <label className="form-label mb-1" htmlFor="channel_url">
                Channel URL
              </label>
              <input
                type="text"
                className="form-control"
                id="channel_url"
                placeholder="Enter channel URL"
                value={formData.channel_url}
                onChange={handleInput}
                required
                disabled={isEdit}
                name="channel_url"
              />
            </div>
            <div className={`col-md-6 mt-2`}>
              <label className="form-label mb-1" htmlFor="channel_no">
                Channel No.
              </label>
              <input
                type="number"
                className="form-control"
                id="channel_no"
                placeholder="Enter channel number"
                value={formData.channel_no}
                onChange={handleInput}
                required
                disabled={isEdit}
                name="channel_no"
              />
            </div>
          </div>

          <div className="row mt-2">
            <div
              className={`${formData.fta === false ? "col-md-6" : "col-md-12"}`}
            >
              <label className="form-label mb-1" htmlFor="SelectOrFta">
                Select Pay or FTA
              </label>
              <select
                id="fta"
                className="form-control pr-1"
                value={formData?.fta?.toString()}
                onChange={handleInput}
                required
                disabled={isEdit}
                name="fta"
              >
                <option disabled value="">
                  Select Option
                </option>
                <option value="true">FTA</option>
                <option value="false">Pay</option>
              </select>
            </div>

            {formData.fta === false && (
              <div className="col-md-6">
                <label className="form-label mb-1" htmlFor="Cost">
                  Cost
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="Cost"
                  value={formData.cost}
                  onChange={handleInput}
                  required
                  disabled={isEdit}
                  name="cost"
                />
              </div>
            )}
          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              <label className="form-label mb-1" htmlFor="definition">
                Definition
              </label>
              <select
                id="definition"
                className="form-control"
                value={formData.definition}
                onChange={handleInput}
                required
                disabled={isEdit}
                name="definition"
              >
                <option disabled value="">
                  Select Definition
                </option>
                <option value="Standard definition">Standard Definition</option>
                <option value="High definition">High definition</option>
              </select>
            </div>
          </div>

          <div className="form-row m-1 d-flex">
            <div className="form-group col-md-5 mt-2 d-flex justify-content-start align-items-center agerestrict">
              {/* <label className="switch">
                <input
                  type="checkbox"
                  id="age_restriction"
                  checked={formData.age_restriction}
                  onChange={handleInput}
                  required
                  disabled={isEdit}
                  name="age_restriction"
                />
                <span className="slider">
                  <svg
                    className="slider-icon"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path fill="none" d="m4 16.5 8 8 16-16"></path>
                  </svg>
                  
                </span>
              </label> */}
              <Switch
                checked={formData.age_restriction}
                onChange={(e) => {
                  setFormData((pre) => {
                    return {
                      ...pre,
                      age_restriction: e.target.checked,
                    };
                  });
                }}
                type="checkbox"
                id="age_restriction"
                disabled={isEdit}
                name="age_restriction"
              />
              <label className="" htmlFor="age_restriction">
                Age Restriction
              </label>
            </div>
          </div>

          {/* {!isEdit ? (
            ""
          ) : (
            <ConfirmModal data={id} onsubmit={DeleteChannel} display={"Channel"}>
              <div className="mt-4">
                <button type="button" className="btn btn-primary">
                  Delete Channel
                </button>
              </div>
            </ConfirmModal>
          )} */}

          {isEdit ? (
            ""
          ) : (
            <div className="d-flex justify-content-end gap-3 mt-4">
              {/* <button className='btn border'>Cancel</button> */}
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          )}
        </form>
        {/* 
        <form className="d-flex flex-column justify-content-between">
              <div className="row">
                <div className="col-md-12 mt-2">
                  <label className="form-label mb-1" htmlFor="name">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter channel name"
                    id="name"
                    value={formData.name}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-md-12 mt-2">
                  <label className="form-label mb-1" htmlFor="description">
                    Description
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Enter description here"
                    value={formData.description}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <div className="form-row mb-3 mt-0 d-flex categoryrow">
                <div className="form-group col-md-12">
                  <label className="form-label mb-1" htmlFor="category">
                    Category
                  </label>
                  <div className="d-flex">
                    <select
                      id="category"
                      className="form-control"
                      value={formData.category}
                      onChange={handleInput}
                    >
                      <option disabled value="">
                        Select Category
                      </option>
                      {categories.map((category) => (
                        <option
                          style={{
                            padding: "10px auto",
                            borderBottom: "0.2px solid black",
                            WebkitAppearance: "none",
                          }}
                          key={category._id}
                          value={category._id}
                        >
                          {category.name}
                        </option>
                      ))}
                    </select>
                    <FaPlus
                      style={{
                        color: "#202020",
                        transform: "scale(1.3)",
                        margin: "auto auto",
                        marginLeft: "10px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={toggleModal}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <label className="form-label mb-1" htmlFor="ChannelGenre">
                    Channel BroadCaster
                  </label>
                  <select
                    required
                    id="select_broadcaster"
                    className="form-control"
                    value={formData.select_broadcaster}
                    onChange={handleInput}
                  >
                    <option disabled value="">
                      Select BroadCaster
                    </option>
                    {broadCast?.map((item) => (
                      <option
                        style={{
                          padding: "10px auto",
                          borderBottom: "0.2px solid black",
                          WebkitAppearance: "none",
                        }}
                        key={item?._id}
                        value={item?._id}
                      >
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-2">
                  <label className="form-label mb-1" htmlFor="ChannelGenre">
                    Channel Genre
                  </label>
                  <select
                    required
                    id="genre"
                    className="form-control"
                    value={formData.genre}
                    onChange={handleInput}
                  >
                    <option disabled value="">
                      Select Genre
                    </option>
                    {genre?.map((item) => (
                      <option
                        style={{
                          padding: "10px auto",
                          borderBottom: "0.2px solid black",
                          WebkitAppearance: "none",
                        }}
                        key={item?._id}
                        value={item?._id}
                      >
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6 mt-2">
                  <label className="form-label mb-1" htmlFor="ChannelLanguage">
                    Channel Language
                  </label>
                  <select
                    required
                    id="language"
                    className="form-control"
                    name="language"
                    value={formData.language}
                    onChange={handleInput}
                  >
                    <option disabled value="">
                      Select Language
                    </option>
                    {lang?.map((item1) => (
                      <option
                        style={{
                          padding: "10px auto",
                          borderBottom: "0.2px solid black",
                          WebkitAppearance: "none",
                        }}
                        key={item1?._id}
                        value={item1?._id}
                      >
                        {item1?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-2">
                  <label className="form-label mb-1" htmlFor="channel_url">
                    Channel URL
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="channel_url"
                    placeholder="Enter channel URL"
                    value={formData.channel_url}
                    onChange={handleInput}
                  />
                </div>
                <div className={`col-md-6 mt-2`}>
                  <label className="form-label mb-1" htmlFor="channel_no">
                    Channel No.
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="channel_no"
                    placeholder="Enter channel number"
                    value={formData.channel_no}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div
                  className={`${
                    formData.fta === false ? "col-md-6" : "col-md-12"
                  }`}
                >
                  <label className="form-label mb-1" htmlFor="SelectOrFta">
                    Select Pay or FTA
                  </label>
                  <select
                    id="fta"
                    className="form-control pr-1"
                    value={formData.fta.toString()}
                    onChange={handleInput}
                  >
                    <option disabled value="">
                      Select Option
                    </option>
                    <option value="true">FTA</option>
                    <option value="false">Pay</option>
                  </select>
                </div>

                {formData.fta === false && (
                  <div className="col-md-6">
                    <label className="form-label mb-1" htmlFor="Cost">
                      Cost
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="Cost"
                      value={formData.cost}
                      onChange={handleInput}
                    />
                  </div>
                )}
              </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <label className="form-label mb-1" htmlFor="definition">
                    Definition
                  </label>
                  <select
                    id="definition"
                    className="form-control"
                    value={formData.definition}
                    onChange={handleInput}
                  >
                    <option disabled value="">
                      Select Definition
                    </option>
                    <option value="Standard definition">
                      Standard Definition
                    </option>
                    <option value="High definition">High definition</option>
                  </select>
                </div>
              </div>
            
              <div className="form-row m-1 d-flex">
                <div className="form-group col-md-5 mt-2 d-flex justify-content-start align-items-center agerestrict">
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="age_restriction"
                      checked={formData.age_restriction}
                      onChange={handleInput}
                    />
                    <span className="slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <label className="" htmlFor="age_restriction">
                    Age Restriction
                  </label>
                </div>
              </div>
            </form> */}
      </div>
    </>
  );
}
